import { inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {filter, map, take} from 'rxjs/operators';
import { UserActions } from '../../../core/modules/user/actions/user.action';
import { IAppState } from '../../../core/ngrx';
import { User } from '../../../shared/models/user/user.model';
import { AuthActions } from '../../../auth';
import { AuthService } from '../../../auth/services/auth.service';
import { isPlatformBrowser } from '@angular/common';



export const ImpersonateGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const platformId = inject(PLATFORM_ID);
  const store = inject(Store);
  const router = inject(Router);
  const authService = inject(AuthService);

  const access = route.params.token;
  const refresh = route.queryParams.refresh;

  if (!access) {
    router.navigate(['/']);
    return true;
  }

  if (!isPlatformBrowser(platformId)) {
    console.warn('ImpersonateGuard: Running on server side.');
    return false;
  }

  store.dispatch(AuthActions.logout());

  authService.logoutFinishedSubject.asObservable().pipe(
    filter(res => !!res),
    take(1)
  ).subscribe(() => {
    authService.setTokenToStorage({access, refresh});
    store.dispatch(new UserActions.LoadAction({access, refresh}));
  });

  return store.pipe(
    select((s: IAppState) => s.user.current),
    filter(_ => !!_),
    map((user: User) => {
      if (user.user_profile && user.active_role === 'employee') {
        const company = user.user_profile.company ? user.user_profile.company :
          user.user_profile.companies.length === 1 ? user.user_profile.companies[0] : null;

        if (company) {
          router.navigate(['/', company.search_name, 'dashboard']);
        } else {
          router.navigate(['/', 'company-select']);
        }
      } else {
        router.navigate(['/bruker']);
      }
      return !!user;
    }),
  );
};

